import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Trash2Icon, MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { useDeleteDocumentMutation } from "@/api/api";
import { useToast } from "@/components/ui/use-toast";
import { DeleteDialog } from "@/components/DeleteDialog";
import { upperFirst } from "lodash";
import { PrimaryAssetType } from "@/api/types/node";
interface CellContentActionsProps {
  id: string;
  primary_asset_type: PrimaryAssetType;
  content_name: string;
}

export const CellContentActions: React.FC<CellContentActionsProps> = ({
  id,
  primary_asset_type,
  content_name,
}) => {
  const [deleteNote, { isLoading }] = useDeleteDocumentMutation();
  const { toast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getItemType = (contentTypeName: PrimaryAssetType): string => {
    switch (contentTypeName) {
      case PrimaryAssetType.PAGE:
        return "page";
      case PrimaryAssetType.FILE:
        return "PDF";
      case PrimaryAssetType.CODEBASE:
        return "codebase";
      default:
        return "item";
    }
  };

  const itemType = getItemType(primary_asset_type);

  const handleDelete = () => {
    deleteNote({
      id: id,
    })
      .then((res) => {
        if (res.error) {
          throw new Error("Failed to delete item");
        }
        toast({
          title: `${upperFirst(itemType)} deleted`,
          description: (
            <span>
              The {itemType} <strong>{content_name}</strong> has been
              successfully deleted.
            </span>
          ),
          variant: "default",
        });
      })
      .catch(() => {
        toast({
          title: `Error deleting ${itemType}`,
          description: "Please try again.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsDialogOpen(false);
      });
  };

  return (
    <div className="p-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="h-6 w-6 p-0"
            onClick={(e) => e.stopPropagation()} // Prevent row click
          >
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          <DropdownMenuItem
            onClick={() => setIsDialogOpen(true)}
            className="text-destructive focus:text-destructive focus:bg-destructive/10 active:bg-destructive/20"
          >
            <Trash2Icon className="w-4 h-4 mr-2" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        itemType={itemType}
        itemName={content_name}
        contentTypeName={primary_asset_type}
        onCancel={() => setIsDialogOpen(false)}
        onDelete={handleDelete}
        isLoading={isLoading}
      />
    </div>
  );
};
