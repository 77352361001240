import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import React from "react";

type BillingBarProps = {
  billingData: {
    percentage: number;
    color: string;
    count?: number;
    label?: string;
  }[];
};

const BillingBar = ({ billingData }: BillingBarProps) => {
  // This is a hack to get the width of the bar and the tooltip to match
  const getBarWidth = (index: number) => {
    setTimeout(() => {
      const container = document.querySelector(".billing-bar");
      if (!container) return;
      const bar = container.querySelector(`.bar-${index}`);
      if (!bar) return 0;
      const tooltip = bar.querySelector(`.tooltip-${index}`);
      if (!tooltip) return;
      (tooltip as HTMLElement).style.width = `${bar?.clientWidth}px`;
    }, 1000);
  };

  return (
    <div className="w-full h-3 bg-gray-300 overflow-hidden">
      <div className="flex h-full billing-bar">
        {billingData.map((data, index) => {
          getBarWidth(index);
          return (
            <div
              key={index}
              className={`h-full ${data.color} overflow-hidden bar-${index}`}
              style={{
                width: `${data.percentage}%`,
                minWidth: data.percentage !== 0 ? 6 : 0,
              }}
            >
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div
                      className={`h-4 tooltip-${index}`}
                      style={{ width: 12 }}
                    ></div>
                  </TooltipTrigger>
                  <TooltipContent>
                    {data.label}:{" "}
                    {new Intl.NumberFormat().format(data.count ?? 0)} SLOC
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BillingBar;
