import * as React from "react";
import { baseUrl, primaryAssetPath, useLazyGetPrimaryAssetQuery } from "@/api/api";
import { FetchPrimaryAssetParams, NodeStatus, PrimaryAssetRecord, PrimaryAssetResponse } from "@/api/types/node";
import { useAuth0 } from "@auth0/auth0-react";
export const LIBRARY_STATUS_CELL_CHANGE = "LIBRARY_STATUS_CELL_CHANGE";

const isFinished = (status: NodeStatus) =>
    status === NodeStatus.CONNECTED ||
    status === NodeStatus.GENERATION_COMPLETE;

const isProcessing = (status: NodeStatus) =>
    status === NodeStatus.CONNECTING || status === NodeStatus.GENERATING;

const POLL_INTERVAL = 10000;
const MAX_POLL_COUNT = 30;
let pollCount = 0;

// we dont want to call unnecessary react hooks as they will cause rerenders to the table
// ie calling rtk hooks will cause rerenders to the table
const fetchPrimaryAssets = async (
    params: FetchPrimaryAssetParams,
    token: string
): Promise<PrimaryAssetResponse> => {
    const stringParams: Record<string, string> = {};
    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
            stringParams[key] = value.toString();
        }
    });
    const queryParams = new URLSearchParams(stringParams);
    const response = await fetch(`${baseUrl}${primaryAssetPath}?${queryParams.toString()}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        throw new Error("Failed to export");
    }
    return response.json();
};


// poll the status of the rows that are processing
// when a row is finished, update the cell status
// by dispatching a custom event
export function useLibraryStatusPoller(data: PrimaryAssetRecord[]) {
    const { getAccessTokenSilently } = useAuth0();
    const [processingRows, setProcessingRows] = React.useState<PrimaryAssetRecord[]>([]);

    React.useEffect(() => {
        if (processingRows.length === 0 || pollCount >= MAX_POLL_COUNT) {
            return;
        }
        let intervalId: NodeJS.Timeout | null = null;
        const fetchRows = async () => {
            if (processingRows.length === 0 || pollCount >= MAX_POLL_COUNT) {
                return;
            }
            try {
                const token = await getAccessTokenSilently();
                const processingIds = processingRows.map(row => row.id);
                const response = await fetchPrimaryAssets({
                    id__in: processingIds.join(","),
                    limit: 50, offset: 0
                }, token);
                const newProcessingRows = response.results.filter((row: PrimaryAssetRecord) => isProcessing(row.versions?.[0]?.status as NodeStatus));
                const newFinishedRows = response.results.filter((row: PrimaryAssetRecord) => isFinished(row.versions?.[0]?.status as NodeStatus));
                // for each new finished row, update the cell status
                newFinishedRows.forEach((row: PrimaryAssetRecord) => {
                    updateCellStatus(row.id, row.versions?.[0]?.status as NodeStatus);
                });
                // only update the state if we have fewer processing rows
                if (newProcessingRows.length < processingRows.length) {
                    setProcessingRows(newProcessingRows);
                    // updating state will trigger a new interval
                    // so we need to clear the old one
                    if (intervalId) {
                        clearInterval(intervalId);
                    }
                }
                pollCount += 1;
            } catch (error) {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            }
        }
        intervalId = setInterval(fetchRows, POLL_INTERVAL);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    }, [processingRows]);

    React.useEffect(() => {
        if (data.length > 0) {
            const rows = data.filter((row: PrimaryAssetRecord) => isProcessing(row.versions?.[0]?.status as NodeStatus));
            setProcessingRows(rows);
        }
    }, [data]);
}

function updateCellStatus(cellId: string, newStatus: NodeStatus) {
    const event = new CustomEvent(LIBRARY_STATUS_CELL_CHANGE, {
        detail: { id: cellId, newStatus },
    });
    window.dispatchEvent(event);
} 