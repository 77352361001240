import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { FileText, FileImage, Code, File, User, Loader2 } from "lucide-react";

interface DeleteDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  itemType: string;
  itemName: string;
  contentTypeName: string;
  onCancel: () => void;
  onDelete: () => void;
  message?: string;
  isLoading?: boolean;
}

const getIcon = (contentTypeName: string) => {
  switch (contentTypeName) {
    case "user":
      return <User className="w-4 h-4 mr-2" />;
    case "application_note":
      return <FileText className="w-4 h-4 mr-2" />;
    case "supplemental-document":
      return <FileImage className="w-4 h-4 mr-2" />;
    case "codebase":
      return <Code className="w-4 h-4 mr-2" />;
    default:
      return <File className="w-4 h-4 mr-2" />;
  }
};

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  onOpenChange,
  itemType,
  itemName,
  contentTypeName,
  onCancel,
  onDelete,
  isLoading,
  message = "will be deleted forever.",
}) => (
  <Dialog open={isOpen} onOpenChange={onOpenChange}>
    <DialogContent>
      <DialogTitle>Delete {itemType}?</DialogTitle>
      <DialogDescription>
        <div className="my-2 flex items-start">
          <span className="mt-0.5">{getIcon(contentTypeName)}</span>
          <span>
            <strong className="mr-0.5">{itemName}</strong> {message}
          </span>
        </div>
      </DialogDescription>
      <div className="mt-4 flex justify-end space-x-2">
        <Button variant="ghost" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="destructive" onClick={onDelete} disabled={isLoading}>
          {isLoading ? "Deleting..." : "Delete"}
          {isLoading && <Loader2 className="w-4 h-4 ml-2" />}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);
