import React from "react";

import BillingBar from "@/components/Settings/Usage/BillingBar";
import { Card, CardContent } from "@/components/ui/card";
import { ArrowUpRightIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useOrg } from "@/contexts/OrgContext";
import AnalysisTooltipPopover from "@/components/Settings/Usage/AnalysisTooltipPopover";
import { ReportData } from "@/components/LibraryTable/GenerateDocs/GenerationSidebarContent";

interface ComputedUsageProps {
  used: number;
  toBeUsed: number;
  total: number;
  isOverage: boolean;
  reportData: ReportData[];
}

function ComputedUsage({
  used,
  toBeUsed,
  total,
  isOverage,
  reportData,
}: ComputedUsageProps) {
  const { organizationDisplayName, organizationName } = useOrg();
  const remaining = total - used - toBeUsed;
  const remainingMin = isOverage ? 0 : remaining;
  const remainingPercentage = (remainingMin / total) * 100;

  const codebaseUsageData = reportData.map((repo) => ({
    percentage: (repo.reportData.analyzable_sloc / total) * 100,
    color: repo.color,
    count: repo.reportData.analyzable_sloc,
    label: repo.repoName,
  }));

  const usageData = [
    {
      percentage: (used / total) * 100,
      color: "bg-blue-500",
      count: used,
      label: "Used",
    },
    ...codebaseUsageData,
    {
      percentage: remainingPercentage,
      color: "bg-gray-500",
      count: remaining,
      label: "Remaining",
    },
  ];

  const sendEmail = () => {
    const subject = "Increase usage limit";
    const orgName = organizationDisplayName ?? organizationName;
    const neededSloc = Intl.NumberFormat().format(Math.abs(remaining));
    const body = `Hello,

I would like to increase my usage limit for my organization ${orgName}.

I need an additional ${neededSloc} SLOC to process these sources.

Thank you,
    `;
    const recipient = "support@driver.ai";

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Card>
      <CardContent className="bg-neutral-50 dark:bg-neutral-900 p-4 rounded-xl">
        <div className="flex justify-between">
          <h4 className="text-md mb-2">
            Computed line usage
            <AnalysisTooltipPopover />
          </h4>
          {isOverage && (
            <Button variant="outline" size="xs" onClick={sendEmail}>
              Adjust limit
              <ArrowUpRightIcon className="w-4 h-4 ml-2" />
            </Button>
          )}
        </div>
        {!isOverage && (
          <p className="text-sm mb-4 text-muted-foreground">
            Importing this source stays within your monthly limit.
          </p>
        )}
        {isOverage && (
          <p className="text-sm mb-4 text-muted-foreground">
            Importing this source would exceed your limit by{" "}
            {new Intl.NumberFormat().format(Math.abs(remaining))} SLOC.
          </p>
        )}
        <div className="flex justify-between align-middle text-sm mt-2">
          <div className="flex-auto mt-1">
            <BillingBar billingData={usageData} />
          </div>
        </div>
        <div className="flex justify-between text-sm mt-2 mb-4">
          <div className="flex font-bold">Total:</div>
          <div className="flex items-center space-x-2 font-mono text-muted-foreground">
            <code>{(used + toBeUsed).toLocaleString()}</code>
            <code> / </code>
            <code>{total?.toLocaleString()}</code>
          </div>
        </div>

        <div className="flex justify-between text-sm mt-2">
          <div>
            <div
              className={`w-2 h-2 bg-blue-500 rounded-full inline-block mr-2`}
            ></div>
            <span className="text-sm">Year to date</span>
          </div>
          <div className="flex items-center space-x-2 font-mono text-muted-foreground">
            <code>{used.toLocaleString()}</code>
          </div>
        </div>
        {reportData.map((repo, i) => {
          return (
            <div
              key={repo.repoName}
              className="flex justify-between text-sm mt-2"
            >
              <div>
                <div
                  className={`w-2 h-2 ${repo.color} rounded-full inline-block mr-2`}
                ></div>
                <span className="text-sm">{repo.repoName}</span>
              </div>
              <div className="flex items-center space-x-2 font-mono text-muted-foreground">
                <code>{repo.reportData.analyzable_sloc.toLocaleString()}</code>
              </div>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default ComputedUsage;
