import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

interface TagProps {
  tag: Tag;
  className?: string;
}

interface Tag {
  name: string;
  hex_color?: string;
  color?: string;
}

export const Tag: React.FC<TagProps> = ({ tag, className }) => {
  const color = tag.hex_color || tag.color;
  return (
    <Badge key={tag.name} variant="outline" className={cn("px-1.5", className)}>
      {color && (
        <span
          className={`w-2 h-2 rounded-full mr-2`}
          style={{ background: color }}
        >
          {" "}
        </span>
      )}
      {tag.name}
    </Badge>
  );
};
