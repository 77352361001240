import React from "react";

import BillingBar from "@/components/Settings/Usage/BillingBar";
import { Card, CardContent } from "@/components/ui/card";
import { ReportData } from "@/components/LibraryTable/GenerateDocs/GenerationSidebarContent";
import { getTopUsedLanguages } from "./utils";

interface TopLanguagesProps {
  reportData: ReportData[];
}

function TopLanguages({ reportData }: TopLanguagesProps) {
  const topLanguages = getTopUsedLanguages(reportData, 4);

  const getColor = (index: number) => {
    const colors = [
      "bg-blue-200",
      "bg-blue-400",
      "bg-blue-600",
      "bg-blue-800",
    ].reverse();
    return colors[index];
  };

  const usageData = topLanguages.map((language, index) => ({
    percentage: language.percentage,
    color: getColor(index),
    count: language.sloc,
    label: language.language,
  }));

  return (
    <Card>
      <CardContent className="bg-neutral-50 dark:bg-neutral-900 p-4 rounded-xl">
        <div className="flex justify-between">
          <h4 className="text-md mb-2">Top Languages</h4>
        </div>

        <div className="flex justify-between align-middle text-sm mt-2">
          <div className="flex-auto mt-1">
            <BillingBar billingData={usageData} />
          </div>
        </div>

        {topLanguages.map((repo, i) => {
          const color = usageData[i].color;
          return (
            <div
              key={repo.language}
              className="flex justify-between text-sm mt-2"
            >
              <div className="w-24 min-w-24">
                <div
                  className={`w-2 h-2 ${color} rounded-full inline-block mr-2`}
                ></div>
                <span className="text-sm">{repo.language}</span>
              </div>
              <span className="text-sm min-w-12">{repo.fileCount} files</span>
              <span className="text-sm min-w-12">
                {Math.round(repo.percentage)}%
              </span>
              <code className="text-sm min-w-12">
                {repo.sloc.toLocaleString()} sloc
              </code>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default TopLanguages;
