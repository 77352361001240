import { ColumnDef, Table } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/LibraryTable/ColumnHeader";
import { CellContentName } from "@/components/LibraryTable/columns/CellContentName";
import { CellContentIcon } from "@/components/LibraryTable/columns/CellContentIcon";
import { CellContentTags } from "@/components/LibraryTable/columns/CellContentTags";
import { CellContentActions } from "@/components/LibraryTable/columns/CellContentActions";
import { PrimaryAssetRecord } from "@/api/types/node";
import { Checkbox } from "@/components/ui/checkbox";
import CellStatus from "./columns/CellStatus";

// we should be able to use `table.getToggleAllRowsSelectedHandler()` here, instead of this
// but it doesnt work
// https://tanstack.com/table/v8/docs/guide/row-selection
const handleAllRowSelectionChange = (table: Table<PrimaryAssetRecord>) => {
  const rows = table.getRowModel().rows;
  const isConnectedRows = rows.filter((row) => row.getCanSelect());
  const currentSelection = table.getState().rowSelection;
  const hasSelection =
    Object.entries(currentSelection)
      .map(([key, value]) => value)
      .filter((value) => value).length > 0;
  const toggleSelected = isConnectedRows.reduce((acc, row) => {
    return {
      ...acc,
      [row.id]: !hasSelection,
    };
  }, {});
  table.setRowSelection((state) => {
    return {
      ...state,
      ...toggleSelected,
    };
  });
};

export const useLibraryTableColumns = (): ColumnDef<PrimaryAssetRecord>[] => {
  return [
    {
      id: "select",
      size: 10,
      header: ({ table }) => {
        const checked = table.getIsAllRowsSelected();
        const rows = table.getRowModel().rows;
        const isConnectedRows = rows.filter((row) => row.getCanSelect());
        return (
          <Checkbox
            className="ml-[5px]"
            {...{
              checked,
              onCheckedChange: () => handleAllRowSelectionChange(table),
              disabled: isConnectedRows.length === 0,
            }}
          />
        );
      },
      cell: ({ row }) => {
        if (row.getCanSelect()) {
          return (
            <div className="px-1">
              <Checkbox
                className="ml-2"
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  onCheckedChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        }
        return <></>;
      },
    },
    {
      accessorKey: "kind",
      header: ({ column }) => <div className="w-4" />,
      size: 10,
      cell: CellContentIcon,
    },
    {
      accessorKey: "display_name",
      enableSorting: true,
      size: 500,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Asset" />
      ),
      cell: CellContentName,
    },
    {
      accessorKey: "tags",
      size: 240,
      enableSorting: false,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="Tags"
          className="text-xs text-primary"
        />
      ),
      cell: (props) => <CellContentTags {...props} />,
    },
    {
      accessorKey: "updated_at",
      size: 10,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Modified" />
      ),
      cell: ({ row }) => {
        const updatedAt = row.original.updated_at;
        const date = new Date(updatedAt);
        return <div>{date.toLocaleDateString()}</div>;
      },
    },
    {
      accessorKey: "status",
      size: 10,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        return <CellStatus row={row} />;
      },
    },
    {
      id: "actions",
      size: 10,
      cell: ({ row }) => (
        <CellContentActions
          id={row.original.id}
          primary_asset_type={row.original.kind}
          content_name={row.original.display_name}
        />
      ),
    },
  ];
};
