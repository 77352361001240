import { CodebaseReportStats } from "./codebase";

export interface FullNodeRecord {
    primary_asset_updated_at: string;
    primary_asset_display_name: string;
    primary_asset_id: string;
    primary_asset_organization_id: string;
    version_id: string;
    version_updated_at: string;
    node_relative_path: string;
    node_updated_at: string;
    primary_asset_created_at: string;
    primary_asset_primary_asset_type: string;
    version_display_name: string;
    version_created_at: string;
    node_id: string;
    node_created_at: string;
}

export interface FullNodeResponse {
    results: FullNodeRecord[];
    total_count: number;
}

export interface VersionDetail {
    id: string;
    primary_asset_id: string;
    display_name: string;
    created_at: string;
    updated_at: string;
    root_node: {
        id: string;
        version_id: string;
        relative_path: string;
        created_at: string;
        updated_at: string;
        misc_metadata: CodebaseReportStats;
    };
    status: string;
}

export interface PrimaryAssetRecord {
    id: string;
    organization_id: string;
    kind: PrimaryAssetType;
    display_name: string;
    created_at: string;
    updated_at: string;
    content?: any;
    tags?: any[];
    versions?: VersionDetail[];
}

export enum PrimaryAssetType {
    FILE = "FILE",
    CODEBASE = "CODEBASE",
    CODEBASE_DIRECTORY = "CODEBASE_DIRECTORY",
    PAGE = "PAGE",
    PAGE_TEMPLATE = "PAGE_TEMPLATE",
    DRIVER_TEMPLATE = "DRIVER_TEMPLATE",
}

export interface PrimaryAssetResponse {
    results: PrimaryAssetRecord[];
    total_count: number;
}

export interface VersionRecord {
    display_name: string;
    primary_asset_id: string;
    id: string;
    created_at: string;
    updated_at: string;
}

export interface VersionResponse {
    results: VersionRecord[];
    total_count: number;
}

export interface NodeRecord {
    id: string;
    relative_path: string;
    version_id: string;
    created_at: string;
    updated_at: string;
}

export interface NodeResponse {
    results: NodeRecord[];
    total_count: number;
}

export enum NodeStatus {
    GENERATION_COMPLETE = "GENERATION_COMPLETE",
    GENERATING = "GENERATING",
    GENERATION_ERROR = "GENERATION_ERROR",
    USER_DATA = "USER_DATA",
    CONNECTED = "CONNECTED",
    CONNECTING = "CONNECTING",
    CONNECTION_FAILED = "CONNECTION_FAILED",
}

export const NodeStatusDisplayName: Record<NodeStatus, string> = {
    [NodeStatus.GENERATION_COMPLETE]: "Generation Complete",
    [NodeStatus.GENERATING]: "Generating",
    [NodeStatus.GENERATION_ERROR]: "Generation Error",
    [NodeStatus.USER_DATA]: "User Data",
    [NodeStatus.CONNECTED]: "Connected",
    [NodeStatus.CONNECTING]: "Connecting",
    [NodeStatus.CONNECTION_FAILED]: "Connection Failed",
};

export interface ContentRecord {
    misc_metadata: Record<string, any>;
    id: string;
    content: string | null;
    content_kind: string;
    version_id: string | null;
    node_id: string;
    content_type_id: string;
    source_content_id: string;
    content_name: string | null;
    tags: any[];
    created_at: string;
    updated_at: string;
    node: {
        id: string;
        version_id: string;
        created_at: string;
        misc_metadata: any | null;
        kind: string;
        relative_path: string;
        updated_at: string;
        version: {
            id: string;
            primary_asset_id: string;
            display_name: string;
            created_at: string;
            updated_at: string;
            status: NodeStatus;
            primary_asset: {
                id: string;
                organization_id: string;
                kind: string;
                display_name: string;
                created_at: string;
                updated_at: string;
            };
            previous_version_id?: string | null;
        };
    };
}

export interface ContentResponse {
    results: ContentRecord[];
    total_count: number;
}

export interface FetchPrimaryAssetParams {
    id?: string;
    id__in?: string;
    display_name__ilike?: string;
    display_name__in?: string;
    limit: number;
    offset: number;
    kind?: PrimaryAssetType[];
    sort_by?: string;
    sort_direction?: "ASC" | "DESC";
    tag_ids?: string[];
    "versions.status"?: NodeStatus[];
}

export interface FetchFullNodeParams {
    limit: number;
    offset: number;
    sort_by: string;
    sort_direction: "ASC" | "DESC";
}

export interface FetchVersionParams {
    limit: number;
    offset: number;
    sort_by: string;
    sort_direction: "ASC" | "DESC";
}

export interface FetchNodeParams {
    limit: number;
    offset: number;
    sort_by: string;
    sort_direction: "ASC" | "DESC";
}

export interface FetchContentParams {
    limit?: number;
    offset?: number;
    sort_by?: string;
    sort_direction?: "ASC" | "DESC";
    node_id?: string;
}

export interface CreateDocumentResponse {
    id: string;
    node_id: string;
    content: string;
    content_kind: string;
    misc_metadata: Record<string, any>;
    created_at: string;
    updated_at: string;
    node: {
        id: string;
        version_id: string;
        relative_path: string;
        kind: string;
        created_at: string;
        updated_at: string;
        version: {
            id: string;
            primary_asset_id: string;
            display_name: string;
            created_at: string;
            updated_at: string;
            status: NodeStatus;
            primary_asset: {
                id: string;
                organization_id: string;
                kind: string;
                display_name: string;
                created_at: string;
                updated_at: string;
            };
        };
    };
}

