import { useCallback, useState, useEffect, useRef } from "react";
import { TagsCreate } from "./TagsCreate";
import { LibraryRecord } from "@/api/types/library";
import { Tag } from "@/components/Tag/Tag";
import { PrimaryAssetRecord } from "@/api/types/node";

interface TagBarProps {
  record: PrimaryAssetRecord;
  showAddTag?: boolean;
}

const cellContentTagsWidth = 105;

export const TableCellTagBar: React.FC<TagBarProps> = ({
  record,
  showAddTag = false,
}) => {
  const [isTagsCreateVisible, setTagsCreateVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const tags = (record?.tags ?? []).filter((tag) => tag.type === "tag");
  const [visibleTags, setVisibleTags] = useState<TagResult[]>([]);

  const updateVisibleTags = useCallback(() => {
    if (!containerRef.current || !tags) return;

    const containerWidth = containerRef.current.clientWidth;
    // causes old cells to still show previous tags
    // if (containerWidth < cellContentTagsWidth) {
    //   setVisibleTags([]);
    //   return;
    // }

    let totalWidth = 0;
    if (tags.length === 0) return;
    const tagsToShow = [...tags]
      .sort((a, b) => a.name.length - b.name.length)
      .filter((tag) => {
        const tagWidth = tag.name.length * 8 + 32; // Approximate width calculation
        if (totalWidth + tagWidth <= containerWidth - 40) {
          // Leave space for "+X" tag
          totalWidth += tagWidth;
          return true;
        }
        return false;
      });
    if (tagsToShow.length !== visibleTags.length) {
      setVisibleTags(tagsToShow);
    }
  }, [tags]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateVisibleTags();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [updateVisibleTags]);

  useEffect(() => {
    setTagsCreateVisible(showAddTag && tags.length === 0);
  }, [showAddTag, tags]);

  return (
    <div
      ref={containerRef}
      className={`relative flex flex-wrap gap-1 w-full h-10 max-h-10 overflow-hidden p-2 group min-w-[${cellContentTagsWidth}px]`}
    >
      <div className="group-hover:opacity-0 transition-opacity duration-200 space-x-1 w-full">
        {!isTagsCreateVisible && (
          <>
            {visibleTags.map((tag) => (
              <Tag key={tag.name} tag={tag} className="h-6 mb-2" />
            ))}
            {visibleTags.length < tags.length && (
              <Tag
                key={"more"}
                tag={{ name: `+${tags.length - visibleTags.length}` }}
                className="h-6"
              />
            )}
          </>
        )}
      </div>

      <div
        className={`absolute inset-2 h-8 ${
          isTagsCreateVisible ? "opacity-100" : "opacity-0"
        } group-hover:opacity-100 transition-opacity duration-200`}
      >
        {record && (
          <TagsCreate
            record={record}
            onClose={() => setTagsCreateVisible(false)}
          />
        )}
      </div>
    </div>
  );
};
