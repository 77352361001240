import {
    useGetPrimaryAssetQuery,
    useGetTagsQuery
} from "@/api/api";
import { FetchPrimaryAssetParams, NodeStatus, PrimaryAssetType } from "@/api/types/node";
import { useLibraryTableColumns } from "@/components/LibraryTable/useLibraryTableColumns";
import {
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useRouter } from "next/router";
import * as React from "react";
import { useGetHasLibraryData } from "./useGetHasLibraryData";
import { useLibraryStatusPoller } from "./useLibraryStatusPoller";

export const defaultContentTypeNames = [
    PrimaryAssetType.CODEBASE, // Codebase
    PrimaryAssetType.PAGE, // Document
    PrimaryAssetType.FILE, // PDF
];

const DEFAULT_PAGE_SIZE = 30;

const getInitialPagination = (query: any) => ({
    pageIndex: parseInt(query.pageIndex as string) || 0,
    pageSize: parseInt(query.pageSize as string) || DEFAULT_PAGE_SIZE,
});

const getInitialColumnFilters = (query: any) => (
    query.columnFilters ? JSON.parse(query.columnFilters as string) : []
);

const getInitialSorting = (query: any) => (
    query.sorting ? JSON.parse(query.sorting as string) : [{ id: "updated_at", desc: true }]
);

const createTagNameToIdMap = (tags: any) => {
    const map: { [key: string]: string } = {};
    tags?.results.forEach((tag: TagResult) => {
        map[tag.name] = tag.id;
    });
    return map;
};

export function useLibraryTable() {
    const router = useRouter();
    const query = router.query;
    const columns = useLibraryTableColumns();
    const [pagination, setPagination] = React.useState(() => getInitialPagination(query));
    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(() =>
        getInitialColumnFilters(query)
    );
    const [sorting, setSorting] = React.useState<SortingState>(() =>
        getInitialSorting(query)
    );

    // Add an effect to update state when query changes
    React.useEffect(() => {
        setPagination(getInitialPagination(query));
        setColumnFilters(getInitialColumnFilters(query));
        setSorting(getInitialSorting(query));
    }, [query]);

    const createFetchParams = (): FetchPrimaryAssetParams => {
        const contentTypeNames: string[] = columnFilters
            .filter((filter) => filter.id === "kind")
            .map((filter) => filter.value as string[])
            .flat();

        const filterIds: string[] = columnFilters
            .filter((filter) => filter.id === "tags")
            .map((filter) => filter.value as string[])
            .flat()
            .map((tagName) => tagNameToIdMap[tagName] || tagName); // Convert tag names to IDs
        const primaryAssetType = contentTypeNames.length > 0 ? contentTypeNames.map((name) => PrimaryAssetType[name as keyof typeof PrimaryAssetType]) : defaultContentTypeNames;
        const searchText = columnFilters.find((filter) => filter.id === "display_name")?.value as string;
        const status = columnFilters.find((filter) => filter.id === "status")?.value as NodeStatus[];
        return {
            'display_name__ilike': searchText ? `%${searchText}%` : undefined,
            limit: pagination.pageSize,
            offset: pagination.pageIndex * pagination.pageSize,
            kind: primaryAssetType,
            sort_direction: sorting[0].desc ? "DESC" : "ASC",
            sort_by: sorting[0].id,
            tag_ids: filterIds,
            "versions.status": status,
        };
    };

    const handlePaginationChange = (updater: any) => {
        const newPagination = typeof updater === 'function' ? updater(pagination) : updater;
        setPagination(newPagination);
        updateQueryParams({ pageIndex: newPagination.pageIndex, pageSize: newPagination.pageSize });
    };

    const handleSortingChange = (updater: any) => {
        const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
        const newPagination = { pageIndex: 0, pageSize: pagination.pageSize };
        setPagination(newPagination);
        setSorting(newSorting);
        updateQueryParams({
            sorting: JSON.stringify(newSorting),
            ...newPagination,
        });
    };

    const handleColumnFiltersChange = (updater: any) => {
        const newFilters = typeof updater === 'function' ? updater(columnFilters) : updater;
        setColumnFilters(newFilters);
        const newPagination = { pageIndex: 0, pageSize: pagination.pageSize };
        setPagination(newPagination);
        updateQueryParams({
            columnFilters: JSON.stringify(newFilters),
            ...newPagination,
        });
    };

    const updateQueryParams = (newParams: Record<string, any>) => {
        router.replace({
            pathname: router.pathname,
            query: {
                ...router.query,
                ...newParams,
            },
        }, undefined, { shallow: true });
    };

    const { hasRecords, isLoading: hasRecordsLoading, isFetching: hasRecordsFetching } = useGetHasLibraryData();
    const { data: tags } = useGetTagsQuery({ type: "tag", limit: 50, offset: 0 });
    const tagNameToIdMap = React.useMemo(() => createTagNameToIdMap(tags), [tags]);
    const params = React.useMemo(() => createFetchParams(), [columnFilters, pagination, sorting, tagNameToIdMap]);
    const { data: response, isLoading: isLoadingResponse, isFetching: isFetchingResponse } = useGetPrimaryAssetQuery(params);
    const isLoading = isLoadingResponse || hasRecordsLoading;
    const isFetching = isFetchingResponse || hasRecordsFetching;
    const data = response?.results ?? [];
    // poll the status of the rows that are processing
    useLibraryStatusPoller(data);

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination,
        },
        getRowId: row => row.id,
        manualPagination: true,
        enableRowSelection: row => row.original.versions?.[0]?.status === NodeStatus.CONNECTED,
        manualFiltering: true,
        rowCount: response?.total_count,
        onPaginationChange: handlePaginationChange,
        onRowSelectionChange: setRowSelection,
        onSortingChange: handleSortingChange,
        getPaginationRowModel: getPaginationRowModel(),
        onColumnFiltersChange: handleColumnFiltersChange,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    return { data, table, isLoading, isFetching, hasRecords };
}
