import { useOrg } from "@/contexts/OrgContext";
import { PrimaryAssetRecord, PrimaryAssetType } from "@/api/types/node";
import { decode } from "he";
import Link from "next/link";
interface CellContentAssetProps {
  row: {
    original: PrimaryAssetRecord;
  };
}
export const CellContentName: React.FC<CellContentAssetProps> = ({ row }) => {
  const { organizationName } = useOrg();
  const rowData = row.original;
  const contentName = rowData.display_name;
  const type = rowData.kind;
  const id = rowData.id;
  const nodeId = rowData.versions?.[0]?.root_node?.id;

  let url = "";
  if (type === PrimaryAssetType.FILE) {
    url = `/${organizationName}/pdf/${nodeId}`;
  } else if (type === PrimaryAssetType.CODEBASE) {
    url = `/${organizationName}/ws/${id}/cb/${id}/tree/${contentName}`;
  } else if (type === PrimaryAssetType.PAGE) {
    url = `/${organizationName}/page/${nodeId}`;
  }

  return (
    <Link
      href={url}
      passHref
      className="max-w-[200px] sm:max-w-[400px] md:max-w-[490px] lg:max-w-[500px] h-9 block"
    >
      <div className="flex items-center h-full">
        <p className="truncate">{decode(contentName)}</p>
      </div>
    </Link>
  );
};
