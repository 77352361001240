import { useGetPrimaryAssetQuery } from "@/api/api";
import { defaultContentTypeNames } from "./useLibraryTable";

export function useGetHasLibraryData() {
  const hasRecordsParams = {
    kind: defaultContentTypeNames,
    limit: 1,
    offset: 0,
  };

  const { data, isLoading, isFetching } =
    useGetPrimaryAssetQuery(hasRecordsParams);
  const recordCount = data?.results?.length ?? 0;
  const hasRecords = recordCount > 0;

  return { hasRecords, isLoading, isFetching };
}
