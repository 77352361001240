import React from "react";
import { NodeStatus, PrimaryAssetRecord } from "@/api/types/node";
import { GenerationSidebar } from "../GenerateDocs/GenerationSidebar";
import { Button } from "@/components/ui/button";
import { Loader } from "lucide-react";
import { LIBRARY_STATUS_CELL_CHANGE } from "../useLibraryStatusPoller";
interface CellStatusProps {
  row: { original: PrimaryAssetRecord };
}

const CellStatus: React.FC<CellStatusProps> = ({ row }) => {
  const status = row.original.versions?.[0]?.status;
  const [currentStatus, setCurrentStatus] = React.useState<NodeStatus>(
    status as NodeStatus
  );

  React.useEffect(() => {
    const handleStatusChange = (event: CustomEvent) => {
      if (event.detail.id === row.original.id) {
        setCurrentStatus(event.detail.newStatus);
      }
    };

    window.addEventListener(
      LIBRARY_STATUS_CELL_CHANGE,
      handleStatusChange as EventListener
    );

    return () => {
      window.removeEventListener(
        LIBRARY_STATUS_CELL_CHANGE,
        handleStatusChange as EventListener
      );
    };
  }, [row.original.id]);

  //  // generating status is also shown for pdfs
  //  // show nothing for non-codebase nodes?
  // if (row.original.kind !== PrimaryAssetType.CODEBASE) {
  //   return <></>;
  // }

  switch (currentStatus) {
    case NodeStatus.CONNECTED:
      return (
        <GenerationSidebar
          selectedRecords={[row.original]}
          buttonText="Generate Docs"
        />
      );
    case NodeStatus.GENERATING:
      return (
        <Button variant="outline" size="sm" disabled>
          Generating... <Loader className="ml-2 w-4 h-4 animate-spin" />
        </Button>
      );
    case NodeStatus.CONNECTING:
      return (
        <Button variant="outline" size="sm" disabled>
          Connecting... <Loader className="ml-2 w-4 h-4 animate-spin" />
        </Button>
      );
    default:
      return <></>;
  }
};

export default CellStatus;
