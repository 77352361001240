import React, { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CircleHelpIcon } from "lucide-react";

interface AnalysisTooltipPopoverProps {}

const AnalysisTooltipPopover: React.FC<AnalysisTooltipPopoverProps> = ({}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open}>
      <PopoverTrigger asChild className="cursor-pointer inline-block">
        <div
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <CircleHelpIcon className="w-4 h-4 text-muted-foreground inline-block ml-2 mt-[-2px]" />
        </div>
      </PopoverTrigger>
      <PopoverContent>
        We determine source lines analyzed by calculating the character bytes of
        source code. One line of source code averages fifty bytes. E.g., 500MB
        of source code would be charged as 10 million lines of source code.
      </PopoverContent>
    </Popover>
  );
};

export default AnalysisTooltipPopover;
