import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { ListEnd, Loader2 } from "lucide-react";
import { GenerationSidebarContent } from "./GenerationSidebarContent";
import {
  useGenerateCodebasesMutation,
  useGetUsageBalanceQuery,
} from "@/api/api";
import { useToast } from "@/components/ui/use-toast";
import { PrimaryAssetRecord } from "@/api/types/node";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

interface GenerationSidebarProps {
  selectedRecords: PrimaryAssetRecord[];
  buttonText?: string;
}

export function GenerationSidebar({
  selectedRecords = [],
  buttonText = "Generate Selected",
}: GenerationSidebarProps) {
  const [generateDocs, { isLoading }] = useGenerateCodebasesMutation();
  const { toast } = useToast();
  const skip = selectedRecords.length === 0;

  const {
    data: usageBalance,
    isLoading: isUsageBalanceLoading,
    error: usageBalanceError,
  } = useGetUsageBalanceQuery(undefined, { skip });

  const versionIds = selectedRecords
    .map((record) => record.versions?.[0]?.id)
    .filter((id) => id !== undefined) as string[];

  const handleGenerateDocs = () => {
    generateDocs({ version_ids: versionIds })
      .unwrap()
      .then(() => {
        toast({
          title: "Codebases generated successfully",
        });
      })
      .catch((error) => {
        toast({
          title: "Error generating codebases",
          // description: error.message,
          variant: "destructive",
        });
      });
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        {selectedRecords.length > 0 && (
          <div className="hidden sm:block">
            <Button className="ml-2" size="sm">
              {buttonText} <ListEnd className="ml-2 h-4 w-4" />
            </Button>
          </div>
        )}
      </SheetTrigger>
      <SheetContent width="585px">
        <SheetHeader>
          <SheetTitle>Confirm Usage</SheetTitle>
        </SheetHeader>
        <div className="py-4 flex-grow">
          {usageBalanceError ? (
            <div className="flex flex-col gap-4">
              <Alert variant="destructive">
                <AlertTitle>Error loading usage balance</AlertTitle>
                <AlertDescription>
                  Please try refreshing the page. If the problem persists,
                  please contact support.
                </AlertDescription>
              </Alert>
            </div>
          ) : (
            <GenerationSidebarContent
              usageBalance={usageBalance}
              isUsageBalanceLoading={isUsageBalanceLoading}
              selectedRecords={selectedRecords}
            />
          )}
        </div>
        <SheetFooter>
          <div className="flex items-center justify-center w-full mt-4">
            <Button
              type="submit"
              onClick={handleGenerateDocs}
              className="w-full"
              disabled={isLoading || !!usageBalanceError}
            >
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <ListEnd className="ml-2 h-4 w-4 mr-2" />
              )}
              {!isLoading && selectedRecords.length > 1
                ? "Generate All Codebases"
                : "Generate"}
              {isLoading && "Generating..."}
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
