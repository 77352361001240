import { useGetUsageBalanceQuery } from "@/api/api";
import ComputedUsage from "./ComputedUsage";
import { CodebaseReportStats } from "@/api/types/codebase";
import { getColorByIndex } from "./utils";
import { Skeleton } from "@/components/ui/skeleton";
import TopLanguages from "./TopLanguages";
import { PrimaryAssetRecord } from "@/api/types/node";
import { UsageBalance } from "@/api/types/usage";

export type ReportData = {
  repoName: string;
  reportData: CodebaseReportStats;
  color: string;
};

interface GenerationSidebarContentProps {
  usageBalance: UsageBalance | undefined;
  isUsageBalanceLoading: boolean;
  selectedRecords: PrimaryAssetRecord[];
}

export function GenerationSidebarContent({
  usageBalance,
  isUsageBalanceLoading,
  selectedRecords,
}: GenerationSidebarContentProps) {
  const reportData: ReportData[] = selectedRecords.map((asset, i) => {
    const reportData = asset.versions?.[0]?.root_node
      .misc_metadata as CodebaseReportStats;
    return {
      repoName: asset.display_name,
      reportData,
      color: getColorByIndex(i),
    };
  });
  const used = Math.abs(usageBalance?.debits ?? 0);
  const toBeUsed = reportData.reduce(
    (acc, curr) => acc + (curr.reportData.analyzable_sloc ?? 0),
    0
  );
  const total = usageBalance?.credits ?? 0;
  const isOverage = toBeUsed + used > total;

  return (
    <div>
      {isUsageBalanceLoading ? (
        <div className="flex flex-col gap-4">
          <Skeleton className="h-52 w-full" />
          <Skeleton className="h-52 w-full" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <ComputedUsage
            used={used}
            toBeUsed={toBeUsed}
            total={total}
            isOverage={isOverage}
            reportData={reportData}
          />
          <TopLanguages reportData={reportData} />
        </div>
      )}
    </div>
  );
}
