import * as React from "react";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";
import { useState } from "react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  ChooseTagColor,
  TagColorOption,
} from "@/components/LibraryTable/ChooseTagColor";
import {
  useAddTagToContentMutation,
  useCreateTagMutation,
  useGetTagsQuery,
  useRemoveTagFromContentMutation,
} from "@/api/api";
import { useToast } from "@/components/ui/use-toast";
import { CircleDashed, Loader } from "lucide-react";
import { ContentRecord, PrimaryAssetRecord } from "@/api/types/node";
import { get } from "lodash";

interface TagsCreateProps {
  title?: string;
  record: PrimaryAssetRecord | ContentRecord;
  onClose: () => void;
  selectedTags?: TagResult[];
}

export function TagsCreate({ record, onClose, selectedTags }: TagsCreateProps) {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [tagIdLoading, setTagIdLoading] = useState("");

  const primaryAssetId = get(
    record,
    "node.version.primary_asset.id",
    record.id
  );
  const documentName =
    "display_name" in record
      ? record.display_name
      : record?.node?.version?.primary_asset?.display_name;
  const [selectedValues, setSelectedValues] = useState<TagResult[]>(
    record?.tags ?? selectedTags ?? []
  );
  const title = "Tags";
  const [createTag, { isLoading: createTagLoading }] = useCreateTagMutation();

  const { data: tags } = useGetTagsQuery({
    type: "tag",
    limit: 1000,
    offset: 0,
  });

  const [sortedTags, setSortedTags] = React.useState<TagResult[]>([]);

  React.useEffect(() => {
    if (tags?.results) {
      const tagList = [...tags.results];
      const sorted = tagList.sort((a, b) => {
        // Sort alphabetically
        return a.name.localeCompare(b.name);
      });
      setSortedTags(sorted);

      // Update selectedValues when tags are loaded
      setSelectedValues(record?.tags ?? selectedTags ?? []);
    }
  }, [tags, record?.tags, selectedTags]);

  const { toast } = useToast();
  const [addTagToContent, { isLoading: tagAddLoading }] =
    useAddTagToContentMutation();
  const [removeTagFromContent, { isLoading: tagRemoveLoading }] =
    useRemoveTagFromContentMutation();
  const tagsLoading = tagAddLoading || tagRemoveLoading;
  // sort tags so that selected ones are first on load
  const makeTag = () => {
    setOpen(true);
  };

  const onSelectTag = (val: string, option: TagResult) => {
    setTagIdLoading(option.id);
    const isSelected = selectedValues.some((sv) => sv.id === option.id);
    if (!isSelected) {
      setSelectedValues([...selectedValues, option]);
      addTagToContent({
        tagId: option.id,
        primaryAssetId,
      })
        .then((d) => {
          if ("error" in d) throw new Error();
        })
        .catch(() => {
          toast({
            title: "Error",
            description: "Failed to associate tag with document",
            variant: "destructive",
          });
          // Revert the selection if the API call fails
          setSelectedValues(selectedValues.filter((sv) => sv.id !== option.id));
        })
        .finally(() => {
          setTagIdLoading("");
        });
    } else {
      setSelectedValues(selectedValues.filter((sv) => sv.id !== option.id));
      removeTagFromContent({
        tagId: option.id,
        primaryAssetId,
      })
        .then((d) => {
          if ("error" in d) throw new Error();
        })
        .catch(() => {
          toast({
            title: "Error",
            description: "Failed to remove tag from document",
            variant: "destructive",
          });
          // Revert the selection if the API call fails
          setSelectedValues([...selectedValues, option]);
        })
        .finally(() => {
          setTagIdLoading("");
        });
    }
  };

  const addTag = async (tag: TagColorOption) => {
    if (inputValue && inputValue?.length === 0) return;
    const tagName = inputValue;
    const newTag = await createTag({
      name: tagName,
      hex_color: tag.color,
      type: "tag",
    })
      .then((d) => {
        if (d.error) throw new Error();
        if (!d.data) throw new Error();
        return d.data;
      })
      .catch(() => {
        toast({
          title: "Error",
          description: `Failed to create tag ${tagName}`,
          variant: "destructive",
        });
      });
    if (!newTag) return;
    addTagToContent({
      tagId: newTag.id,
      primaryAssetId,
    })
      .then((d) => {
        if (d.error) throw new Error();
        setOpen(false);
        setInputValue("");
        toast({
          title: "Tag created",
          description: (
            <span>
              <strong>{tagName}</strong> has been added to{" "}
              <strong>{documentName}</strong>
            </span>
          ),
        });
      })
      .catch(() => {
        toast({
          title: "Error",
          description: `Failed to associate tag ${tagName} with document ${documentName}`,
          variant: "destructive",
        });
      })
      .finally(() => {
        setInputValue("");
        setOpen(false);
      });
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      modal={true}
      onOpenChange={(open: boolean) => {
        setIsPopoverOpen(open);
        if (open) {
          // Re-sort tags when popover is opened
          const sorted = [...sortedTags].sort((a, b) => {
            const aSelected = selectedValues.some((val) => val.name === a.name);
            const bSelected = selectedValues.some((val) => val.name === b.name);
            if (aSelected !== bSelected) {
              return aSelected ? -1 : 1;
            }
            return 0; // Maintain alphabetical order for equal selection status
          });
          setSortedTags(sorted);
        }
        if (!open) {
          onClose();
        }
      }}
    >
      <ChooseTagColor
        name={inputValue}
        open={open}
        setOpen={setOpen}
        setInputValue={setInputValue}
        callback={addTag}
        isLoading={tagsLoading}
      />
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={`w-full h-full -mt-1 justify-start items-center border-dashed transition-opacity duration-200 ${
            isPopoverOpen ? "opacity-0 pointer-events-none" : "opacity-100"
          }`}
        >
          <CircleDashed className="mr-2 h-3 w-3 flex-shrink-0" />
          {(record?.tags?.length ?? 0) > 0 ? "Edit tags" : "Add tags"}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-56 p-0" align="start" forceMount>
        <Command>
          <CommandInput
            placeholder={`Filter ${title?.toLowerCase()}...`}
            className="border-none focus:ring-0 ps-0"
            value={inputValue}
            onValueChange={setInputValue}
          />
          <CommandList>
            {inputValue.length > 0 && (
              <CommandEmpty className="p-2">
                <Button
                  variant="secondary"
                  className="w-full justify-start"
                  leadingIcon="PlusIcon"
                  onClick={makeTag}
                >
                  <span className="truncate">Create new tag: {inputValue}</span>
                </Button>
              </CommandEmpty>
            )}
            {inputValue.length === 0 && <CommandEmpty>No results</CommandEmpty>}
            <CommandGroup>
              {sortedTags.map((option) => {
                const isSelected = selectedValues.some(
                  (val) => val.id === option.id
                );
                return (
                  <CommandItem
                    key={option.id}
                    className="w-full"
                    onSelect={(val) => onSelectTag(val, option)}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected
                          ? "bg-primary text-primary-foreground"
                          : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      {tagIdLoading === option.id ? (
                        <Loader className={cn("h-4 w-4, animate-spin")} />
                      ) : (
                        <CheckIcon className={cn("h-4 w-4")} />
                      )}
                    </div>
                    <div className="flex items-center">
                      <span
                        className={`w-2 h-2 rounded-full mr-2`}
                        style={{ background: option.hex_color }}
                      ></span>
                      <span className="flex-1 text-left truncate">
                        {option.name}
                      </span>
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            <CommandGroup className="sticky bottom-0">
              {inputValue.length > 0 && (
                <CommandItem className="p-2 -mb-1 bg-background data-[selected=true]:bg-background">
                  <Button
                    variant="secondary"
                    className="w-full justify-start"
                    leadingIcon="PlusIcon"
                    onClick={makeTag}
                  >
                    <span className="truncate">
                      Create new tag: {inputValue}
                    </span>
                  </Button>
                </CommandItem>
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
