import { PrimaryAssetRecord } from "@/api/types/node";
import { TableCellTagBar } from "@/components/Tag/TableCellTagBar";
import { Row } from "@tanstack/react-table";

interface CellContentTagsProps {
  row: {
    getValue: <T>(key: string) => T;
  };
}

export const CellContentTags: React.FC<CellContentTagsProps> = ({ row }) => {
  const record = row as Row<PrimaryAssetRecord>;
  if (!record) return <></>;
  return <TableCellTagBar record={record?.original} />;
};
